<template>
  <div
    class="
      widgetContainer
      bg-white
      checkDepositSuccess
      appStatus appStatus--approved
    ">
    <div class="navigationHeader drawer-header-default">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('RCD_captureScreen_navTitle') }}
        </p>
        <p class="cancel" @click="done">
          <i class="el-icon-close" />
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <LottieAnimation animation-name="success" reference="check-deposit-success" :loop="true" />
      
      <p class="title">
        {{ $t('RCD_successTitle') }}
      </p>
      <p class="description">
        {{ $t('RCD_successDesc') }}
      </p>

      <ul class="checkDepositSuccess__details">
        <li>
          <span class="label">{{ $t('pay_row_amount') }}:</span>
          <div>
            <p>{{ getSuccessDepositData.amount | currency }}</p>
          </div>
        </li>
        <li>
          <span class="label">{{ $t('RCD_deposit_to') }}:</span>
          <div>
            <p v-if="isBusinessAccountType">
              {{ getSelectedBusiness.legalName }}
            </p>
            <span>{{ getSelectedAccount.label }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        class="welcome__get-started el-button__brand brand width-100"
        @click="done">
        {{ $t('done') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('account', ['getSelectedAccount', 'isBusinessAccountType']),
    ...mapGetters('checkDeposit', [
      'getSuccessDepositData',
      'getSelectedContact'
    ])
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations('checkDeposit', ['setSelectedContact']),
    done() {
      this.drawerClose();
      this.setSelectedContact(null);
      this.goToDashboard();
    }
  }
};
</script>

<style lang="scss">
.checkDepositSuccess {

  .description {
    padding-bottom: 32px;
    white-space: pre-line;
    padding: 16px 10px 32px;
  }

  &__details {
    border: 1px solid #edeff1;
    border-radius: var(--radius);
    padding: 24px 20px;
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      padding-bottom: 18px;
      text-align: left;
      font-size: 14px;
      line-height: 18px;

      .label {
        min-width: 140px;
        color: var(--grey-1);
      }

      div {
        span {
          color: var(--grey-1);
        }
      }
    }
  }
}
</style>
